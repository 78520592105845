
class BrandApi {

    getList() {
        var url = apiUrl + 'brands';
        return api.call('get', url)
            .then(({ data }) => {
                return data
            });
    }

    get(id) {
        var url = apiUrl + 'brands/';
        return api.call('get', url + id)
            .then(({ data }) => {
                return data
            });
    }
 
    create(data) {
        var url = apiUrl + 'brands';
        return api.call('post', url, data)
            .then(({ data }) => {
                return data
            });

    }
    update(data) {
        var url = apiUrl + 'brands/';
        return api.call('put', url + data.id, data)
            .then(({ data }) => {
                return data
            });
    }
    delete(id) {
        var url = apiUrl + 'brands/';
        return api.call('delete', url + id)
            .then(({ data }) => {
                return data
            });
    }

    removeModel(data){
        var url = apiUrl + 'brands/RemoveModel/';
        return api.call('delete', url + data.brandId + "/" + data.id)
            .then(({ data }) => {
                return data
            });
    }

    addModel(brandId, data)
    {
        var url = apiUrl + 'brands/AddModel/';
        return api.call('post', url + brandId, data)
            .then(({ data }) => {
                return data
            });
    }

}

export default BrandApi;